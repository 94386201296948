.wrap{
  word-break:break-all;
  word-wrap:break-word;
  line-height: 18px;
  margin-bottom: 0;
}
.ant-notification ,.ant-notification-topRight{
  position: fixed;
  right: 0;
  top: 0!important;
  left: 0;
  bottom: 0;
  margin: 0 auto;
}
