.headerInfo {
  position: relative;
  text-align: center;
  & > span {
    color: rgba(0,0,0,.45);
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 4px;
  }
  & > p {
    color: rgba(0,0,0,.85);
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }
  & > em {
    background-color:rgba(0,0,0,.09);
    position: absolute;
    height: 56px;
    width: 1px;
    top: 0;
    right: 0;
  }
}
@media screen and (max-width: 576px) {
  .headerInfo {
    margin-bottom: 16px;
    & > em {
      display: none;
    }
  }
}
