.login-page {
  display: flex;
  height: 100%;
  //background:linear-gradient(to left bottom, rgb(3, 41, 83), rgba(65, 75, 180, 0.74));
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../assets/image/background.jpg");
  background-position: top center;
  background-size: cover;
}



.login-panel {
  padding:20px;
  margin-top: 30px;
  border-radius: 10px;
  background: #fff;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 2px 28px rgba(0,0,0,.1);
}
