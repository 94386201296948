#slider-layout-side .logo {
    text-align: center;
    height: 60px;
    margin: 16px;
}

.slider-layout-header {
    box-shadow: 0px 3px 10px #00000020;
}

.logo-image{
    border-radius: 10px;
}
